<template>
  <div class="about">
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="~static/debris.png" />
    <img class="Aboutbg" style="top: 17%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 60%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 33%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 56%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="bottom: 0; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 34%; right: 0" src="~static/wasteR.png" />
    <img
      class="Aboutbg"
      style="bottom: 13%; right: 0"
      src="~static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 170px 0">
            <div style="width: 100%">
              <!-- <hr /> -->
              <div class="hr-more">
                <div>조자니(條子泥) 관광지 소개</div>
              </div>

              <div class="scenic">
                <div style="position: relative">
                  <span class="video-box"></span>
                  <video
                    style="object-fit: fill; margin-top: -81px"
                    width="550"
                    height="500"
                    poster="~static/videoFm.png"
                    controls="controls"
                    src="http://117.80.146.163:18080/tznmh/tzn_img/tiaozini.mp4"
                    type="video/mp4"
                  ></video>
                </div>
                <div
                  style="
                    position: relative;
                    z-index: 2;
                    width: 430px;
                    height: 400px;
                    overflow-y: auto;
                    padding-right: 16px;
                  "
                >
                  <!-- <img class="general01" src="~static/general01.png" />
                  <img class="general02" src="~static/general02.png" />
                  <img class="general05" src="~static/general05.png" /> -->
                  <!-- <p v-html="decodeHTML( tzn_gkText[0] && tzn_gkText[0].content)"></p> -->
                  <!-- <p style="text-align: left"> -->
                  <p>
                    둥타이(東臺) 조자니(條子泥) 습지는 황해 생태구 남쪽에 있는
                    둥타이 연해 지역에 위치한다. 총 면적이 600km2이고 그중
                    조자니 관광지의 면적은 10.77km2이다. 지앙쑤(江苏)성에서
                    첫번째, 중국에서 14번째 세계 자연유산지 외에 조자니 습지는
                    또한 중국 임해 습지류 세계 자연유산의 공백을 보완하였다.
                  </p>
                  <p>
                    조자니는 바다에 의해 탄생하고 새의 서식지로 유명한다.
                    난온대와 아열대의 과도 지역에 위치하며 기후가 따뜻하고
                    강우량이 풍족하다. 미사진흙의 갯벌은 수분과 염분 등 영양질을
                    고정시킬 수 있다. 게다가 패류가 풍부하고 풀밭이 광활하며
                    딱딱한 암석 해안보다 생물 생존에 더 유리하다. 따라서 수많은
                    새를 이끌어 잠깐 멈추고 털갈이하고 겨울을 보낸다. 세계 9대
                    조류 이동 구역 중 동아시아-오스트랄라시아 철새 이동 노선의
                    핵심 구역이 되었다. 총 조류 410종을 지니고 그중 물새가
                    170종이 되고 수량이 100만 마리에 달한다. 또한 그중 12가지
                    종이 IUCN 레드리스트에 수록되었다. 중국 국가 1급, 2급 보호
                    조류에 수록된 종은 각각 21가지와 71가지에 달한다. 따라서
                    조자니는 조류의 국제공항으로 칭송받았다.
                  </p>
                  <p>
                    중화 민족의 젖줄로 여겨진 창장(長江)과 황허(黃河)는 동쪽으로
                    흘러가는 도종 엄청난 진흙과 모래를 휩쓸며 이곳에서 합류하고
                    침전하여 복사사척군(輻射沙脊群), 양분수(兩分水),
                    일선조(一線潮)와 조석 삼림 등 중국 연해 지역 특유의 지형 및
                    바다 일출, 하늘의 거울(天空之境), 깨끗한 별 하늘, 저녁노을과
                    외로운 들오리, 중국홍 등 아름다운 자연 경치를 이루었다.
                  </p>
                  <p>
                    조자니는 녹색이고 홍색이다. 이곳은 중국인민해방군 해군의
                    출발지이다. 신사군 소중군구 해방단은 이곳에서 바다에 들어가
                    상품 판매를 암호로 하고 붉은 돛단배를 이용하여 쑤베이(지앙쑤
                    북부), 루난(산둥 난부)와 상하이 사이에서 항일 군수품을
                    운송하는 해상 수송선을 개척하였다. 영화 ‘51호 병참’은 바로
                    붉은 돛단배들이 바람을 타고 파도를 헤치고 앞으로 나아가는
                    융감한 이미지를 묘사하였다. 그후 붉은 돛단배는 또한 유명한
                    도강 전투에 투신하여 중국인민해방군 해군의 커다란 힘이
                    되었다. 붉은 돛단배는 돛이 붉은색으로 나타나기에 이름이
                    붙여졌다. 붉은 돛단배는 자싱(嘉興) 남호(南湖)의 홍선과 함께
                    아주 중요한 역사적 가치를 지닌다. 현재 붉은 돛단배는
                    조자니의 항구에 조용히 머물어 사람들에게 그 파란만장한
                    세월을 서술하고 있다.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div
              class="generalImg"
              :style="{ 'background-image': 'url(' + backgroundImg + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat' }"
            ></div>-->
          </div>
          <div
            id="introduce"
            class="introduce"
            style="padding: 120px 80px 0 80px"
          >
            <div class="hr-more">명소 소개</div>
            <div style="width: 77%; margin: 0 auto">
              <div class="introduce_tabs">
                <span
                  v-for="(item, index) in tabsList"
                  style="width: 16.67%"
                  :key="item.name"
                  @click="ApiintroduceList(String(index))"
                >
                  <img width="113px" :src="item.src" />
                  <p>{{ item.name }}</p>
                </span>
              </div>
              <div style="margin-top: 80px; padding-left: 100px; display: flex">
                <div
                  style="
                    width: 40%;
                    margin-right: 50px;
                    padding-top: 25px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <p class="introduceText" v-html="content"></p>
                </div>
                <div style="width: 880px; position: relative">
                  <img
                    style="position: absolute; z-index: 9"
                    src="~static/introducebg01.png"
                    alt=""
                  />
                  <el-carousel
                    height="500px"
                    style="width: 110%; margin-left: 60px; margin-top: 30px"
                  >
                    <el-carousel-item v-for="i in introduceList" :key="i.id">
                      <el-image
                        style="height: 600px"
                        :fit="'cover'"
                        :src="i.thumbnail"
                      ></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="hr-more">사 진</div>
            <div class="imgBox">
              <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
                stretch
                style="width: 100%; padding: 0 30px"
              >
                <el-tab-pane
                  v-for="i in imageTitleList"
                  :key="i.id"
                  :label="i.catalogueName"
                  :name="i.id"
                >
                  <div
                    class="bird-image"
                    v-if="i.id == '402880447ba001ab017ba0096fdb005f'"
                  >
                    <div
                      class="brief"
                      @click="handleGo(item.birdType, item.catalogueId)"
                      v-for="item in pictureList"
                      :key="item.id"
                    >
                      <div
                        class="bird-box"
                        :style="{
                          'background-image': 'url(' + item.picUrl + ')',
                        }"
                      >
                        <!-- <p>
                          {{ item.birdType && item.birdType.split("|")[3] }}
                        </p> -->
                        <img
                          src="~static/scenery/brid-box.png"
                          style="opacity: 0.7"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="scenic-cultural" v-else>
                    <div
                      class="tzn-brief"
                      v-for="item in pictureList"
                      :key="item.id"
                    >
                      <div
                        class="bird-box"
                        :style="{
                          'background-image': 'url(' + item.picUrl + ')',
                        }"
                      >
                        <img
                          src="~static/scenery/brid-box.png"
                          style="opacity: 0.7"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="greatDeeds">
            <div style="width: 60%; margin: 0 auto; position: relative">
              <div class="hr-more">동영상</div>
              <div class="movie">
                <div
                  class="video"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                  <span class="video-box2"></span>
                  <video
                    style="object-fit: fill"
                    controls="controls"
                    :src="item.videoPath"
                    type="video/mp4"
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds">
            <div class="hr-more">멋진 순간</div>
            <div>
              <div class="Hrbg2" style="font-size: 16px">
                조자니(條子泥)에 포커스 맞춤·촬영 모임
              </div>
              <div class="tabs">
                <div
                  @click="tabsListApi(2, '402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum2 ? 'tabsB' : ''"
                >
                  왕년의 작품.
                </div>
                <div
                  @click="tabsListApi(1, '402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum2 ? '' : 'tabsB'"
                >
                  현지
                </div>
              </div>
              <div class="imgBox">
                <div
                  v-for="item in tabs01List"
                  :key="item.id"
                  :style="{
                    'background-image': 'url(' + item.picUrl + ')',
                    margin: '40px',
                    'background-repeat': 'no-repeat',
                    'background-position': '50%',
                    'border-radius': '50%',
                    'background-size': 'cover',
                  }"
                >
                  <img
                    style="margin-left: -40px"
                    src="~static/tabsbg01.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="Hrbg2">갯벌 놀이</div>
            <div class="imgBox">
              <div
                v-for="item in tabs02List"
                :key="item.id"
                :style="{
                  'background-image': 'url(' + item.picUrl + ')',
                  margin: '20px',
                  'background-repeat': 'no-repeat',
                  width: '370px',
                  height: '244px',
                  'background-size': 'cover',
                }"
              >
                <img
                  style="margin-left: -10px"
                  src="~static/tabsbg02.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="greatDeeds" style="padding: 120px 80px 0 80px">
            <div class="Hrbg2">철인 3종 경기</div>
            <div class="imgBox">
              <div
                v-for="item in tabs04List"
                :key="item.img"
                :style="{
                  'background-image': 'url(' + item.picUrl + ')',
                  margin: '50px',
                  'background-repeat': 'no-repeat',
                  width: '348px',
                  height: '348px',
                  'background-size': 'cover',
                }"
              >
                <img
                  style="margin-left: -4px"
                  src="~static/tabsbg04.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            id="geography"
            class="geography"
            style="padding: 120px 80px 80px 80px"
          >
            <div class="hr-more">찾아오시는 길</div>
            <div
              style="height: 750px; display: flex; margin: 0 auto; width: 90%"
            >
              <div class="greatDeeds_2">
                <p style="font-weight: bold">
                  자가운전 여행: <br /><br />
                  노선 1: <br />
                  →G15 선하이(선양-하이커우) 고속도로 <br />
                  →S352 성급(省級) 도로를 따라 동쪽으로 42km 운전<br />
                  →조자니(條子泥)관광지 도착 <br /><br />
                  노선 2: <br />
                  →G228국도 <br />
                  →왼쪽으로 돌며 S352성급(省級) 도로를 따라 500m 운전<br />
                  →조자니(條子泥)관광지 도착 <br /><br />

                  버스: <br />
                  둥타이 버스 터미널--조자니(條子泥)관광지 <br />
                  6:10、6:40、7:40、11:40、14:40<br />
                  조자니(條子泥)관광지--둥타이 버스 터미널<br />
                  8:40、9:40、10:10、10:40、<br />
                  11:40、12:10、13:10、14:10、<br />
                  14:40、17:50、<br />
                  산창 열사 묘지--조자니(條子泥)관광지 <br />
                  8:00、9:00、12:30、13:30、<br />
                  14:30、15:30<br />
                  조자니(條子泥)관광지--산창 열사 묘지 <br />
                  9:50、10:50、13:30、14:30、<br />
                  14:50、16:50<br /><br />
                  문의 전화：0515-85680999 <br />
                  구급 전화：0515-85680996 <br />
                  신고 전화：0515-85680998 <br />
                </p>
              </div>
              <iframe
                v-if="flag"
                style="width: 100%; height: 100%; margin-left: 20px"
                name="my-iframe"
                id="my-iframe"
                src="https://apis.map.qq.com/uri/v1/marker?marker=coord:32.760766,120.908494;title:条子泥游客中心;&referer=myapp"
                frameborder="0"
                width="100vw"
                height="100vh"
                scrolling="no"
              ></iframe>
            </div>
          </div>
          <div class="greatDeeds">
            <div class="hr-more">
              <div>관람비 및 서비스</div>
            </div>
            <div>
              <div class="essentialImg">
                <img style="width: 65%" src="~static/KOessential1.png" alt="" />
              </div>
            </div>
            <div class="essentialImg">
              <img
                style="vertical-align: middle; width: 100%"
                src="~static/KOessential4.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="Lfloat" ref="Lfloat">
          <div>
            <!-- <p>走进条子泥</p> -->
            <!-- <a href="javascript:;" @click="backTop">条子泥印象</a> -->
            <router-link to="/Korean?name=Introduction"
              >관광지 소개</router-link
            >
            <router-link to="/Korean?name=ScenicSpot">명소 소개</router-link>
            <router-link to="/Korean?name=Pictures">사 진</router-link>
            <router-link to="/Korean?name=Videos">동영상</router-link>
            <router-link to="/Korean?name=Review">멋진 순간</router-link>
            <router-link to="/Korean?name=Traffic">찾아오시는 길</router-link>
            <router-link to="/Korean?name=TicketandService"
              >관람비 및 서비스</router-link
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
import { tzn_tplbAPI, tzn_tpxqAPI, tzn_jmspAPI } from "@/api/test/scenery";
export default {
  name: "About",
  data() {
    return {
      list: ``,
      slideshowList: ``,
      // backgroundImg: ``,
      introduceList: {},
      content:
        "조자니(條子泥)고수위 서식지는 총 면적이 720묘(약 48만 평방미터)에 달하며 수상, 육지와 하늘에서 720도로 전방위적으로 관람할 수 있어서 720이라고 불리게 됩니다. 2020년 4월에 철새를 위해 만조(滿潮)를 피하여 개척한 서식지입니다. 이곳에 서식하고 있는 새는 그 종류가 410개에 걸쳐 수만 마리에나 달하는데 글로벌조류보호기구에 의해 ‘철새 보호의 모범’이라고 칭찬받았습니다. ",
      tabsList: [
        { name: "7 2 0", src: require("static/tabs01.png") },
        { name: "양분수", src: require("static/tabs02.png") },
        { name: "일선조", src: require("static/tabs03.png") },
        { name: "습지 중국홍", src: require("static/tabs04.png") },
        { name: "하늘의 거울", src: require("static/tabs05.png") },
        { name: "조석 삼림", src: require("static/tabs06.png") },
      ],
      tabs01List: [],
      tabs02List: [],
      tabs04List: [],
      activeNum: 4,
      activeNum2: true,
      tzn_gkText: ``,
      tzn_jtznText: ``,
      greatDeeds_list: ``,
      activeName: "402880447ba001ab017ba0096fdb005f",
      imageTitleList: [
        { id: "402880447ba001ab017ba0094ba9005b", catalogueName: "경치" },
        {
          id: "402880447ba001ab017ba0096fdb005f",
          catalogueName: "조류. 새 강",
        },
        { id: "402880447ba001ab017ba009aa340063", catalogueName: "인문 경관" },
      ],
      pictureList: [],
      videoList: [],
      flag: false,
    };
  },
  methods: {
    ApiintroduceList(key) {
      switch (key) {
        case "0":
          api.tzn_sdjs().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "조자니(條子泥)고수위 서식지는 총 면적이 720묘(약 48만 평방미터)에 달하며 수상, 육지와 하늘에서 720도로 전방위적으로 관람할 수 있어서 720이라고 불리게 됩니다. 2020년 4월에 철새를 위해 만조(滿潮)를 피하여 개척한 서식지입니다. 이곳에 서식하고 있는 새는 그 종류가 410개에 걸쳐 수만 마리에나 달하는데 글로벌조류보호기구에 의해 ‘철새 보호의 모범’이라고 칭찬받았습니다. ";
          break;
        case "1":
          api.tzn_efs().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "조자니(條子泥) 조수는 또한 독특한 양분수(兩分水) 경치가 있습니다. 둥타이(東臺)의 해역에는 크기와 방향이 다양한 유동성 사주 수십 개가 분포합니다. 그중 동사(东沙) 사주와 서사(⻄沙) 사주 사이에 남북을 잇는 해역이 있습니다. 이 나팔 모양으로 된 해역은 서양(⻄洋)이라고 불립니다. 만조(滿潮)가 될때마다 황해 서쪽 해역에서 용솟음치며 흘러온 조석파가 서양 북쪽에 들어가고 동해 북쪽 해역에서 흘어온 조석파가 서양 남쪽에 들어가는데 두 가지 조석파는 서양에서 만나고 부딪쳐 아주 웅장한 양분수 경치를 이룹니다. 조자니에서 일년 내내 모두 양분수 경치를 구경할 수 있습니다. 7월, 8월과 9월 장마철이 되는 동안 7급 이상의 북동풍이 부는 날에 만조가 될때 생긴 서로 양보하지 않는 두 가지 조석파는 서양의 U자형 복도에 들어가 높이가 1-2m에 달한 호두조(⻁头潮)를 이룹니다. 앞서가는 조수가 떨어지지 못하고 뒤쪽의 조수가 바로 따라잡습니다. 조수가 서로 앞을 다투어 쫓고 쫓기고 하며 합치면 관세음 보살의 합장과 같고 갈라지면 펑펑 쏟아져 내리는 함박눈이와 같습니다. 이런 웅장한 장면은 10-20분이나 지속할 수 있습니다. 그리고 재미있는 것은 조수가 밀려 나가면서 서양에서 합류한 두 가지 조석파는 왔던 길을 따라 헤어져 하늘과 맞닿는 먼 바다에 흘러 들어갑니다.";
          break;
        case "2":
          api.tzn_sdtm().then((res) => {
            this.introduceList = res;
            this.content =
              "추분이 되는 시기 조자니의 조수 경치가 가장 웅장하고 특히 해상 풍력이 강한 경우 심지어 일선조(一線潮)를 구경할 수 있습니다. 일선조는 조수가 해면에서 용솟음치면서 서서히 하얀 줄을 이루는 경치를 말합니다. 이와 동시에 와르르 소리를 냅니다. 소리가 커지면서 하얀 줄은 먼 해면에서 신속하게 앞으로 나아가 마치 해면을 가로지르는 하얀 무지개와 같습니다. 하얀 무지개 벽이 앞으로 나아가면서 조수는 내달리는 천군만마와 같아 기세가 아주 웅장합니다. ";
          });
          break;
        case "3":
          api.tzn_gcth().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "나문재는 일년생 식물이며 보통 해안, 황무지, 수로 기슭과 밭 기슭등 알칼리성 토지에 자랍니다. 나문재는 검은머리갈매기에게 가장 이상적인 번식지입니다. 조자니 나문재 경치는 해경로 북쪽에 위치하며 면적이 약 500묘(약 333헥타르)에 달합니다. 가을이 되면 이 광활한 습지가 바로 눈부신 붉은색으로 되는데 습지 레드카펫으로 불립니다. 인터넷 스타로서 조자니 습지는 선후 수차례 신화 통신넷과 강국 등 여러 대중 매체에 올랐습니다.";
          break;
        case "4":
          api.tzn_hqxq().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "조류는 아직 물러서지 않았고, 바다는 부드러운 거울처럼, 하늘과 땅이 구름 바다에서 당신을 보자. 쌍으로, 거울 세계를 형성한다.";
          break;
        case "5":
          api.tzn_sdsj().then((res) => {
            this.introduceList = res;
          });
          this.content =
            "조수가 밀려 나가는 흔적은 사주에서 수많은 독특하고 아름다운 그림을 남깁니다. 조석의 침식으로 이룬 도랑은 하늘을 찌를 듯한 큰 나무와 같고 수많은 나무는 또한 삼림을 이룹니다. 따라서 이런 경치는 조석 삼림이라고 불리게 됩니다. ";
          break;
        default:
          break;
      }
    },
    //鸟类图片跳转
    handleGo(birdType, catalogueId) {
      this.$router.push({
        path: "/scenerydetails",
        query: {
          birdType: birdType.split("|")[2],
          catalogueId,
        },
      });
    },
    //teb切换
    handleClick(tab, event) {
      console.log(tab, event);
      tzn_tpxqAPI({
        isUse: 1,
        catalogueId: this.activeName,
        size: this.activeName == "402880447ba001ab017ba0096fdb005f" ? 8 : 6,
        page: 0,
      }).then(({ data }) => {
        this.pictureList = data;
      });
    },
    tabsListApi(e = 1, activeId) {
      api
        .layUIPage({
          isUse: "1",
          activeId,
          picType: e,
          size: 6,
          page: 0,
        })
        .then(({ data }) => {
          switch (activeId) {
            case "402880447baab07f017baac4d5fc00bb": // 摄影欢乐会
              this.tabs01List = data;
              e == 1 ? (this.activeNum2 = true) : (this.activeNum2 = false);
              break;
            case "402880447baab07f017baac511bd00c0": //滩玩儿
              this.tabs02List = data;
              break;
            case "402880447baab07f017baac5d7dc00cf": // 铁人三项
              this.tabs04List = data;
              break;
            default:
              break;
          }
        });
    },
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 430) {
        this.$refs.Lfloat.style.top = 617 - scrollTop + "px";
      } else {
        this.$refs.Lfloat.style.top = "30%";
      }
      if (scrollTop > 7700) {
        this.flag = true;
      }
      console.log(scrollTop, "位置");
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  created() {},
  mounted() {
    //图片目录
    // this.$nextTick(() =>{
    // tzn_tplbAPI().then((res) => {
    //   this.imageTitleList = res;
    // });
    // })

    //精美视频
    tzn_jmspAPI({
      isUse: 1,
      size: 6,
      page: 0,
    }).then((res) => {
      this.videoList = res.data;
    });
    this.handleClick(); //精美图片
    api.tzn_sdjs().then((res) => {
      this.introduceList = res;
    });
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    api.tzn_gk().then(({ data }) => {
      this.tzn_gkText = data;
    });
    api.tzn_jtzn().then(({ data }) => {
      this.tzn_jtznText = data;
    });
    api.tzn_zdsj().then(({ data }) => {
      this.greatDeeds_list = data;
    });
    this.tabsListApi(2, "402880447baab07f017baac4d5fc00bb"); // 摄影欢乐会
    this.tabsListApi(0, "402880447baab07f017baac511bd00c0"); //滩玩儿
    this.tabsListApi(0, "402880447baab07f017baac5d7dc00cf"); // 铁人三项
    window.addEventListener("scroll", this.windowScroll);

    // console.log(this.introduceList);
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style scoped>
.essentialImg {
  text-align: center;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("~static/tab.png") no-repeat;
}
.tabsB {
  background: url("~static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  width: 440px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  margin: 0 auto;
  font-size: 28px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #f6d397;
  background: url("~static/Hrbg2.png") no-repeat;
}
.movie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.image {
  width: 450px;
  height: 250px;
  background-image: url("../../../static/scenery/img-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 250px;
  margin: 55px auto;
  letter-spacing: 10px;
  font-size: 42px;
  color: #509ab1;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
.video {
  position: relative;
  margin-bottom: 30px;
  width: 31%;
  height: 355px;
}
.video-box2 {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-image: url("../../../static/scenery/video-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -2px;
}
.video > video {
  width: 98%;
  height: 100%;
}
/deep/ .el-tabs__item {
  background-image: url("~static/scenery/bgbox.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #f6d397;
  margin: 0 15px;
  padding: 0;
  height: 60px;
  line-height: 60px;
  font-size: 28px;
}
/deep/ .el-tabs__item.is-active {
  background-image: url("~static/scenery/bgbox-select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #509ab1;
}
/deep/ .el-tabs__active-bar {
  display: none;
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__nav.is-stretch {
  min-width: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  margin-bottom: 52px;
}
.brief {
  margin: 8px;
  width: 23%;
  cursor: pointer;
}
.tzn-brief {
  margin: 8px;
  width: 30%;
  cursor: pointer;
}
.bird-box {
  position: relative;
  background-repeat: no-repeat;
  background-size: 98% 98%;
}
.bird-box > p {
  position: absolute;
  top: 21%;
  left: 10%;
  width: 30px;
  font-size: 28px;
  font-family: "tzn-font";
  color: #fff;
  line-height: 40px;
}
.bird-box > img {
  display: block;
  width: 100%;
  height: auto;
}
.bird-image,
.scenic-cultural {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.video-box {
  width: 783px;
  height: 700px;
  background-image: url(~static/videoBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -116px;
  top: -29%;
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
>>> .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #509ab1 !important;
}
.top-title {
  background-size: cover;
  background-image: url(/static/img/title.ab49d158.png);
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs,
.scenic {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.introduce_tabs:before,
.introduce_tabs:after,
.scenic:before,
.scenic:after {
  content: "";
  display: block;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
  white-space: nowrap;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  /* position: absolute; */
  margin: 40px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.greatDeeds_bzt {
  height: 100%;
  width: 2px;
  margin: 0 auto;
  border: none;
  background: darkgray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.greatDeeds_bzt > div {
  height: 136px;
  margin: 40px 0;
}
.greatDeeds_bzt > div > h2 {
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.generalbg {
  left: -45%;
  top: -27%;
}
.general01 {
  right: -27%;
}
.general02 {
  right: -36%;
  top: 65%;
}
.general03 {
  height: 140px;
  left: -18%;
  top: -6%;
}
.general04 {
  bottom: -25%;
  left: 88%;
  z-index: -1;
}
.general05 {
  left: -30%;
}
.general06 {
  writing-mode: tb-rl;
  position: absolute;
  right: -51%;
  top: 4%;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #363a31;
  line-height: 24px;
}
.greatDeeds {
  /* background: url("~static/greatDeedsimg.png") no-repeat center;
  background-size: cover;
  height: 1000px; */
  /* transform: scale(0.93, 1); */
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("~static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("~static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}
.Lfloat {
  /* display: none; */
  position: fixed;
  width: 352px;
  height: 527px;
  left: -2%;
  top: 617px;
  z-index: 99;
  background: url("~static/flotbg2.png") no-repeat 100% 100%;
  /* transform: scale(0.75); */
}
.Lfloat > div {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 38%;
}
.Lfloat > div > p {
  text-align: center;
  height: 23px;
  font-size: 24px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #5098af;
  line-height: 11px;
}
ul {
  list-style: none;
}
.Lfloat a {
  display: block;
  color: #5098af;
  line-height: 27px;
  margin: 13px 0px -3px 0px;
  text-align: center;
  width: 128px;
  height: 29px;
  background: url("~static/flotInbg.png") no-repeat 100% 100%;
}
.introduceText {
  white-space: pre-wrap;
  overflow-y: auto;
  margin-top: 10px;
}
.introduce {
  height: 1100px;
  /* background: url("~static/introducebg.png") no-repeat; */
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  margin-left: 10%;
  /* width: 45%; */
  height: 100%;
  overflow-y: auto;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}
.icon1 {
  background: url("~static/msg.png") no-repeat;
}
.icon2 {
  background: url("~static/site.png") no-repeat;
}
.icon3 {
  background: url("~static/time.png") no-repeat;
}
.icon4 {
  background: url("~static/icon4.png") no-repeat;
}

>>> .el-tabs__content {
  /* height: 300px; */
}
.hr-more {
  white-space: nowrap;
  /* letter-spacing: 3px; */
  line-height: 250px;
  z-index: 9;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -19px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("~static/Hrbg.png");
  font-size: 21px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
  text-shadow: ;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.about {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("~static/generalbg.png") no-repeat -6% -49%; */
}
.general p {
  text-indent: 28px;
  text-align-last: start;
  line-height: 2.5;
  /* writing-mode: tb-rl; */
  /* letter-spacing: 12px; */
}
.generalImg {
  flex: 1;
}
.imgBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: scale(0.8);
  width: 1400px;
  margin: 0 auto;
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>
